@import '../../../sass/global.scss';
:local .roomList {
    .title {
        font-size: 14px;
        height: 55px;
        text-align: left;
        line-height: 55px;
        border-bottom: 1px solid $mainBoxShadowColor;
        margin-bottom: 15px;
        background: $mainWhiteColor;
        padding: 0 30px;
    }
    .filters {
        padding: 15px;
        margin: 15px 15px 0;
        background: $mainWhiteColor;
        display: flex; // justify-content: space-between;
        align-items: center;
        .search {
            width: 450px;
            font-size: 14px;
            color: $mainWhiteColor;
        }
        .selectDate {
            width: 450px;
        }
        .item {
            .drown {
                margin-left: 15px;
            }
            .filterBtn {
                margin-right: 15px;
                width: 80px;
            }
        }
    }
    .datePicker {
        flex: 0.5;
    }
    .chooseDateSection {
        margin-left: 30px;
    }
}
