@import "../../../sass/global.scss";
:local .info {
  .title {
    font-size: 14px;
    height: 55px;
    text-align: left;
    line-height: 55px;
    border-bottom: 1px solid $mainBoxShadowColor;
    background: $mainWhiteColor;
    padding: 0 30px;
  }
  .hidden {
    display: none;
  }
  .prompt {
    background: $mainWhiteColor;
    padding: 15px;
  }
  .basicWrap {
    display: flex;
    flex-direction: column;
    margin: 15px;
    background: $mainWhiteColor;
    min-height: 68vh;
    .form {
      margin-top: 20px;
      width: 100%;
      :global .ant-form-item-label {
        // text-align: left;
        :global .ant-form-item-required:before {
          content: "";
        }
      }
      .item {
        min-height: 45px;
        line-height: 45px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 15px;
        :local .ant-form-item-children {
          display: flex;
        }
        .name {
          font-size: 14px;
          text-align: right;
          color: $mainTextColor;
          margin-right: 20px;
          width: 150px;
          text-align: right;
        }
        .detail {
          font-size: 14px;
          color: $mainTextColor;
          padding: 0 10px;
          width: 250px;
          border: 1px solid $mainBorderColor;
          border-radius: 5px;
          height: 35px;
          line-height: 35px;
          overflow: hidden;
        }
      }
      .tooltip {
        color: $mainBorderColor;
        margin-left: 5px;
      }
      .autoLogin {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .autoLoginTitle {
          flex: 1;
          text-align: left;
        }
        .forgetPassword {
          width: 150px;
          text-align: right;
        }
      }
      .verificationCode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .verificationCodeInput {
          flex: 1;
        }
        .verificationCodeBtn {
          width: 150px;
          text-align: right;
        }
      }
      .verificationCodePic {
        display: flex;
        align-items: center;
        .input {
          flex: 1;
        }
        .img {
          margin-left: 10px;
          width: 120px;
          height: 40px;
          cursor: pointer;
        }
      }
      .autoLogin {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checkBox {
          flex: 1;
          text-align: left;
        }
        .checkBoxForget {
          width: 120px;
          text-align: right;
        }
      }
      .control {
        display: flex;
        align-items: center;
        padding: 0 15%;
        .btn {
          margin-right: 15px;
          margin-top: 45px;
        }
      }
      .controlLegalPerson {
        display: flex;
        align-items: center;
        .btn {
          margin-right: 15px;
          margin-top: 150px;
        }
      }
      .realNameTitle {
        font-size: 14px;
        color: $mainTextColor;
        height: 40px;
        line-height: 40px;
      }
      .input {
        width: 100%;
      }
      .panel {
        margin-bottom: 25px;
        .shareholderList {
          .shareholderItem {
            .shareholderTitle {
              display: flex;
              align-items: center;
              .theme {
                font-size: 14px;
                color: $mainTextColor;
              }
              .icon {
                font-size: 16px;
                color: $warning;
                margin-left: 15px;
                cursor: pointer;
              }
            }
            .item {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              padding-left: 10px;
              .label {
                font-size: 14px;
                color: $mainText8Color;
                width: 100px;
                text-align: right;
                padding-right: 10px;
              }
              .input {
                flex: 1;
              }
            }
          }
        }
        .shareholderAdd {
          text-align: center;
          font-size: 18px;
          color: $mainTextColor;
        }
      }
    }
  }
}

:local .drawer {
  .stepName {
    font-size: 16px;
    color: $mainTextBlackColor;
    height: 40px;
    line-height: 40px;
  }
  .prompt {
    margin: 15px 0;
    font-size: 14px;
    color: $mainText8Color;
    border: 2px dotted $mainBorderColor;
    border-radius: 5px;
    line-height: 1.5;
    padding: 15px;
  }
  .stepChargeUserList {
    .stepChargeUseTitle {
      font-size: 14px;
      color: $mainText8Color;
      height: 45px;
      line-height: 45px;
    }
    .chargeUserItem {
      display: flex;
      align-items: center;
      padding-left: 30px;
    }
    .chargeUserNick {
      font-size: 14px;
      color: $mainText8Color;
      height: 40px;
      line-height: 40px;
    }
    .icon {
      font-size: 13px;
      color: $warning;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border: 1px solid $warning;
      border-radius: 50%;
      margin-left: 15px;
      cursor: pointer;
    }
  }
  .promptShow {
    margin-bottom: 30px;
  }
  .selectBtn {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .btn {
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .stepChargeUserName {
    display: flex;
    align-items: center;
    .name {
      width: 80px;
      font-size: 14px;
      color: $mainText8Color;
    }
  }
}

:local .hidden {
  display: none;
}

:local .modal {
  position: relative;
  text-align: center;
  .adminList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .adminItem {
      height: 45px;
      line-height: 45px;
      cursor: pointer;
      margin: 0 15px;
      .adminTitle {
        font-size: 14px;
        color: $mainTextColor;
        &:focus {
          border: 1px solid $mainBorderColor;
        }
      }
      .adminIsChoose {
        border-radius: 5px;
        border: 1px solid $mainSelectBgColor;
        color: $mainSelectBgColor;
        font-size: 14px;
        &:focus {
          border: 1px solid $mainSelectBgColor;
        }
      }
    }
  }
  .saveBtn {
    cursor: pointer;
    margin-top: 15px;
  }
}
