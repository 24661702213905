@charset "utf-8";
$carouselPointColor: darkgrey; //轮播图小圆点颜色
$activeCarouselPointColor: #007bff;
$mainWhiteColor:#fff;
$mainTextBlackColor: #333;
$mainTextColor: #666;
$warning:rgb(237, 6, 6);
$mainText8Color: #888;
$mainText6Color: #666;
$mainBgColor:#f5f5f5;
$mainSidebarBlackBgColor:#000c17; //侧边栏栏目展开深色
$mainSidebarBgColor:#001529; //侧边栏栏目颜色
$mainSidebarFooterBgColor:#002140; //侧边栏底部颜色
$mainSelectBgColor:#1890ff; //选中的颜色
$mainBorderColor:#ccc;
$mainBoxShadowColor:rgba(0, 21, 41, 0.08);
$mainBoxBgColor:#d7dbe0; //控制台小图标的颜色
$mainHoverBgColor:#f0f2f5; //鼠标移入元素背景颜色变色
$mainHoverBgColor1:#f5f5f9; //鼠标移入元素背景颜色变色
$mainGreen:#52c41a;
$mainBottomColor:#e8e8e8;
$textColor:rgba(0, 0, 0, 0.65);
$textGrayColor:rgba(0, 0, 0, 0.45);
$maskLayer:rgba(0, 0, 0, 0.12);
$loadingBg:rgba(0, 0, 0, 0.22);
$lineBg:rgba(0, 0, 0, 0.04);
// :global .flex {
//     display: flex;
//     &.center {
//         justify-content: center;
//     }
//     &.v-center {
//         align-items: center;
//     }
//     &.wrap {
//         flex-wrap: wrap;
//     } 
// }
// /* For demo */
// .ant-carousel .slick-slide {
//     text-align: center;
//     height: 160px;
//     line-height: 160px;
//     background: #364d79;
//     overflow: hidden;
// }
// .ant-carousel .slick-slide h3 {
//     color: $mainWhiteColor;
// }
// .separate-line {
//     margin: 0 8px;
//     display: inline-block;
//     height: .9em;
//     width: 1px;
//     background: $mainBoxBgColor;
//     vertical-align: middle;
//     position: relative;
//     top: -.06em;
// }
// //aliIcon
// .icon {
//     width: 1em;
//     height: 1em;
//     vertical-align: -0.15em;
//     fill: currentColor;
//     overflow: hidden;
// }
// body {
//     font-family: "微软雅黑";
// }
// input:-webkit-autofill {
//     -webkit-box-shadow: 0 0 0px 1000px $mainWhiteColor inset !important;
//     -webkit-text-fill-color: $textColor !important;
// }
