@import '../../global.scss';
html {
    touch-action: none;
}

:local .tarbar {
    position: relative;
    .content {
        :global .am-tab-bar-bar {
            position: fixed;
        }
        .icon {
            font-size: 23px;
        }
        .selectIcon {
            font-size: 23px;
            color: $mainSelectBgColor;
        }
        .page {
            padding-bottom: 70px;
            background-color: $mainHoverBgColor1;
            min-height: 100vh;
            .banner {
                height: 200px;
                overflow: hidden;
                img {
                    width: 100%;
                    vertical-align: top;
                }
            }
            .homeList {
                //home
                .item {
                    padding: 0 15px;
                    width: 100%;
                    background: $mainWhiteColor;
                    .title {
                        position: relative;
                        width: 100%;
                        height: 45px;
                        line-height: 45px;
                        font-size: 14px;
                        color: $mainTextBlackColor;
                        font-weight: 600;
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            border-bottom: 1px solid $mainHoverBgColor1;
                            -webkit-transform: scaleY(.5);
                            transform: scaleY(.5);
                        }
                    }
                    .secondsList {
                        margin: 15px 0 0;
                        .theme {
                            width: calc(100% / 4);
                            text-align: center;
                            margin-bottom: 15px;
                            .icon {
                                width: 50px;
                                height: 50px;
                                border-radius: 5px;
                                overflow: hidden;
                                margin: 0 auto;
                                border: 1px solid $mainHoverBgColor;
                                img {
                                    width: 100%;
                                }
                            }
                            .name {
                                font-size: 14px;
                                color: $textGrayColor;
                                width: 86px;
                                margin: 5px auto 0;
                                text-align: center;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
            .apply {
                position: relative;
                .banner {
                    position: relative;
                    height: 120px;
                    padding-top: 70px;
                    overflow: visible;
                    margin-bottom: 30px;
                    .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 120px;
                        overflow: hidden;
                        img {
                            width: 100%;
                        }
                    }
                    .filters {
                        position: relative;
                        margin: 0 15px;
                        height: 60px;
                        background: $mainWhiteColor;
                        box-shadow: 0 2px 4px $mainBoxBgColor;
                        border-radius: 5px;
                        .line {
                            width: 1px;
                            height: 35px;
                            background: $mainHoverBgColor;
                        }
                        .item {
                            flex: 1;
                        }
                    }
                }
                .applyList {
                    .item {
                        margin: 0 15px;
                        padding: 15px;
                        box-shadow: 0 0 12px $mainBoxBgColor;
                        border-radius: 5px;
                        margin-bottom: 20px;
                        background: $mainWhiteColor;
                        .title {
                            font-size: 15px;
                            color: $mainTextColor;
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .time {
                            flex: 1;
                            font-size: 12px;
                            color: $mainText8Color;
                            text-align: right;
                        }
                        .icon {
                            font-size: 23px;
                            color: $mainTextColor;
                            margin-right: 5px;
                        }
                    }
                }
                :global {
                    .am-list-item .am-list-line .am-list-extra {
                        flex: 1;
                        text-align: center;
                        max-width: 70px;
                        font-size: 14px;
                    }
                    .am-list-item .am-list-line .am-list-arrow-horizontal {
                        transform: rotate(90deg);
                    }
                }
            }
            .personalCenter {
                position: relative;
                min-height: 90vh;
                .navbar {
                    position: relative;
                }
                .header {
                    position: relative;
                    padding: 25px 25px;
                    text-align: center;
                    border: 1px solid $mainHoverBgColor;
                    border-radius: 5px;
                    background: $mainWhiteColor;
                    margin: 10px 0;
                    .avatar {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        border: 3px solid $mainBoxBgColor;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .nick {
                        font-size: 16px;
                        color: $mainTextColor;
                        margin: 15px 0 6px;
                    }
                    .phone {
                        font-size: 14px;
                        color: $mainText8Color;
                    }
                }
                .list {
                    border-radius: 5px;
                    .item {
                        width: 100%;
                        height: 45px;
                        line-height: 45px;
                        justify-content: space-between;
                        align-items: center;
                        text-align: center;
                        display: flex; // margin: 5px 0;
                        padding: 0 15px;
                        background: $mainWhiteColor;
                        border-bottom: 1px solid $lineBg;
                        .icon {
                            font-size: 25px;
                            color: $mainTextColor;
                        }
                        .leftIcon {
                            font-size: 15px;
                            color: $textGrayColor;
                        }
                        .title {
                            margin-left: 10px;
                            font-size: 14px;
                            color: $mainTextBlackColor;
                        }
                    }
                    .settings {
                        width: 100%;
                        height: 45px;
                        line-height: 45px;
                        justify-content: space-between;
                        align-items: center;
                        text-align: center;
                        display: flex; // margin: 5px 0;
                        padding: 0 15px;
                        background: $mainWhiteColor;
                        border-bottom: 1px solid $lineBg;
                        margin-top: 10px;
                        .icon {
                            font-size: 25px;
                            color: $mainTextColor;
                        }
                        .leftIcon {
                            font-size: 15px;
                            color: $textGrayColor;
                        }
                        .title {
                            margin-left: 10px;
                            font-size: 14px;
                            color: $mainTextBlackColor;
                        }
                    }
                }
            }
        }
    }
}

:local .loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $loadingBg;
    z-index: 101 !important;
    justify-content: center;
}
