@import "../../../global.scss";
:local body {
  background: $mainWhiteColor !important;
}

:local .company {
  position: relative;
  overflow-y: auto;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: $maskLayer;
  }
  .hidden {
    display: none;
  }
  .tips {
    padding: 10px 18px;
    font-size: 14px;
    color: $mainTextColor;
    border: 2px dotted $mainTextColor;
    // margin: 0 12px;
    border-radius: 5px;
    margin-top: 10px;
    white-space: normal;
  }
  :global .ant-upload {
    line-height: 0.5;
    margin: 5px 2px 0 !important;
  }
}

:local .operation {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
  .btn {
    width: 30%;
  }
}

:local .form {
  font-size: 14px;
  color: $mainText8Color;
  min-height: 95vh;
  background: $mainWhiteColor;
  input {
    text-align: right;
    font-size: 16px !important;
    color: $mainText8Color !important;
  }
  textarea {
    font-size: 16px;
    color: $mainText8Color;
  }
  .isGraduate {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }
  :global .am-list-item .am-input-label {
    font-size: 15px;
    color: $mainTextColor;
    width: 100px !important;
  }
  :global .am-list-item .am-list-line .am-list-content {
    font-size: 15px;
    color: $mainTextColor;
  }
  .theme {
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    color: $mainTextBlackColor;
  }
  .formItem {
    padding-left: 15px;
    .title {
      height: 45px;
      line-height: 45px;
      font-size: 15px;
    }
    .detail {
      padding-right: 15px;
      flex: 1;
      justify-content: flex-end;
      display: flex;
    }
  }
  .addPatent {
    text-align: center;
    height: 45px;
    line-height: 45px;
    .icon {
      font-size: 20px;
      color: $mainText8Color;
    }
  }
  .deleteIcon {
    color: $warning;
    font-size: 18px;
    margin-left: 15px;
  }
  .address {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .hasStudyAbroad {
    flex: 1 1;
    justify-content: flex-end;
    display: flex;
    padding-right: 23px;
  }
}
:local .diploma {
  margin-left: 15px;
  color: #888;
  font-size: 15px;
  margin-top: 15px;
}
:local .diplomaPic {
  margin-left: 15px;
}
