@import "../../../sass/global.scss";
:local .content {
  position: relative;
  .title {
    font-size: 14px;
    height: 55px;
    text-align: left;
    line-height: 55px;
    border-bottom: 1px solid $mainBoxShadowColor;
    margin-bottom: 15px;
    background: $mainWhiteColor;
    padding: 0 30px;
  }
  .loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $maskLayer;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .filters {
    padding: 15px;
    margin: 15px 15px 0;
    background: $mainWhiteColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
      width: 388px;
      font-size: 14px;
      color: $mainWhiteColor;
    }
    .item {
      .drown {
        margin: 0 15px;
      }
    }
  }
  .table {
    background: $mainWhiteColor;
    padding: 15px;
    margin: 0 15px 15px;
    .avatarBox {
      width: 60px;
      cursor: pointer;
      .avatar {
        width: 100%;
      }
    }
    .btn {
      cursor: pointer;
      color: $mainSelectBgColor;
    }
  }

  .flex {
    display: flex;
  }
  .filter {
    margin-right: 10px;
  }
  .filterNameList {
    display: flex;
  }
  .filterName {
    flex: 1;
  }
}
.formTwo {
  border: 1px solid red;
}
.formSpeciality {
  border: 1px solid red;
}
.specialityList {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid red;
  width: 100%;
}
.specialityItem {
  width: 33%;
  border: 1px solid green;
  .ogiTitle {
    background: $mainHoverBgColor;
    padding-left: 15px;
  }
}
