@import '../../global.scss';
:local body {
    background: $mainWhiteColor !important;
}

body {
    background: $mainHoverBgColor1 !important;
}

:local .settings {
    position: relative;
    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        background: $maskLayer;
    }
    .hidden {
        display: none;
    }
    .navbar {
        border-bottom: 1px solid $mainBottomColor;
    }
    .content {
        .list {
            margin-top: 10px;
            .item {
                padding: 0 10px;
                height: 45px;
                line-height: 45px;
                border-bottom: 1px solid $mainBottomColor;
                display: block;
                background: $mainWhiteColor;
                .title {
                    font-size: 14px;
                    color: $mainText6Color;
                }
                .icon {
                    width: 40px;
                    font-size: 20px;
                    color: $mainText6Color;
                }
                .iconLeft {
                    font-size: 15px;
                    color: $mainText6Color;
                }
            }
        }
    }
}
