@import '../../../sass/global.scss';
:local .info {
    .title {
        font-size: 14px;
        height: 55px;
        text-align: left;
        line-height: 55px;
        border-bottom: 1px solid $mainBoxShadowColor;
        background: $mainWhiteColor;
        padding: 0 30px;
    }
    .hidden {
        display: none;
    }
    .prompt {
        background: $mainWhiteColor;
        padding: 15px;
    }
    .content {
        margin: 15px;
        padding: 15px;
        background: $mainWhiteColor;
        .step {
            min-width: 800px;
            max-width: 90%;
            margin: 0 auto;
        }
        .userInfo {
            display: flex;
            justify-content: space-around;
            margin: 45px 0;
            .infoList {
                position: relative;
                .item {
                    height: 45px;
                    line-height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-bottom: 15px;
                    .name {
                        font-size: 14px;
                        text-align: right;
                        color: $mainTextColor;
                        margin-right: 20px;
                        width: 150px;
                        text-align: right;
                    }
                    .detail {
                        font-size: 14px;
                        color: $mainTextColor;
                        padding: 0 10px;
                        width: 250px;
                        border: 1px solid $mainBorderColor;
                        border-radius: 5px;
                        height: 35px;
                        line-height: 35px;
                    }
                }
                .itemImg {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-bottom: 15px;
                    .name {
                        font-size: 14px;
                        text-align: right;
                        color: $mainTextColor;
                        margin-right: 20px;
                        width: 150px;
                        text-align: right;
                    }
                    .img {
                        width: 250px;
                        max-height: 150px;
                        overflow: hidden;
                        img {
                            width: 100%;
                            cursor: pointer;
                        }
                    }
                }
            }
            .chargeUserList {
                width: 400px;
                padding: 15px;
                border: 1px solid $mainBoxBgColor;
                border-radius: 10px;
                .theme {
                    font-size: 16px;
                    color: $mainText6Color;
                    text-align: center;
                    height: 45px;
                    line-height: 45px;
                }
                .reviewStyle {
                    font-size: 15px;
                    color: $mainText8Color;
                    height: 40px;
                    line-height: 40px;
                    .reviewStyleTitle {
                        font-size: 16px;
                        color: $mainSelectBgColor;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
                .chargeUser {
                    display: flex;
                    justify-content: space-between;
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    color: $mainText8Color;
                }
                .stepIsPass {
                    .result {
                        font-size: 14px;
                        color: $mainGreen;
                    }
                }
                .stepIsReject {
                    .result {
                        font-size: 14px;
                        color: $warning;
                    }
                }
                .stepIsProcess {
                    .result {
                        font-size: 14px;
                        color: $mainSelectBgColor;
                    }
                }
                .chargeUserMsg {
                    font-size: 14px;
                    color: $mainText8Color;
                }
            }
        }
        .sendMsg {
            margin: 20px auto 0 auto;
            width: 500px;
            height: 200px;
        }
        .operationList {
            display: flex;
            justify-content: space-around;
            .item {
                margin: 0 10px;
            }
        }
        .msgList {
            width: 800px;
            margin: 0 auto;
            padding: 15px;
            border: 1px solid $mainBoxBgColor;
            border-radius: 5px;
            .msgListTitle {
                font-size: 15px;
                color: $mainTextBlackColor;
                height: 35px;
            }
            .item {
                .author {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 45px;
                    .msgSendUserNick {
                        font-size: 14px;
                        color: $mainTextColor;
                    }
                    .msgSendTime {
                        font-size: 12px;
                        color: $mainBorderColor;
                    }
                }
                .msg {
                    text-align: left;
                    height: 30px;
                    line-height: 30px;
                    .message {
                        padding: 0 5px;
                        display: inline-block;
                        border: 1px solid $mainBorderColor;
                        font-size: 14px;
                        color: $mainBorderColor;
                        border-radius: 10px;
                    }
                }
                .selfMsg {
                    text-align: right;
                    .message {
                        border: 1px solid $mainSelectBgColor;
                        color: $mainSelectBgColor;
                        padding: 0 5px;
                        display: inline-block;
                        font-size: 14px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

:local .modal {
    text-align: center;
    img {
        width: 350px;
    }
}
