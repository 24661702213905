@import "../../../sass/global.scss";
:local .edit {
  .title {
    font-size: 14px;
    height: 55px;
    text-align: left;
    line-height: 55px;
    border-bottom: 1px solid $mainBoxShadowColor;
    background: $mainWhiteColor;
    padding: 0 30px;
  }
  .hidden {
    display: none;
  }
  .prompt {
    background: $mainWhiteColor;
    padding: 15px;
  }
  .content {
    margin: 15px;
    background: $mainWhiteColor;
    min-height: 68vh;
    .affairsName {
      padding: 0 15px;
      font-size: 16px;
      height: 45px;
      line-height: 45px;
      color: $mainText8Color;
      border-bottom: 1px solid $mainHoverBgColor;
      .affairsTitle {
        font-size: 15px;
      }
    }
    .processList {
      padding: 15px;
      display: flex;
      align-items: center;
      .theme {
        font-size: 16px;
        color: $mainText8Color;
      }
      .step {
        font-size: 14px;
        color: $mainText8Color;
        margin-left: 10px;
        border: none;
        outline: none;
        border-bottom: 1px dotted transparent;
        &:hover {
          border-bottom: 1px dotted $mainBoxBgColor;
        }
        &:focus {
          border-bottom: 1px solid $mainBoxBgColor;
          outline: none;
          box-shadow: 0 0 0 #fff;
        }
      }
      .item {
        display: flex;
        align-items: center;
        .icon {
          font-size: 13px;
          color: $warning;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          border: 1px solid $warning;
          border-radius: 50%;
          cursor: pointer;
        }
      }
      .processItem {
        width: 200px;
        cursor: pointer;
        .name {
          font-size: 14px;
          color: $mainText8Color;
          text-align: center;
        }
      }
      .processBtn {
        margin: 0 0 0 30px;
      }
    }
  }
}

:local .drawer {
  .stepName {
    font-size: 16px;
    color: $mainTextBlackColor;
    height: 40px;
    line-height: 40px;
  }
  .prompt {
    margin: 15px 0;
    font-size: 14px;
    color: $mainText8Color;
    border: 2px dotted $mainBorderColor;
    border-radius: 5px;
    line-height: 1.5;
    padding: 15px;
  }
  .stepChargeUserList {
    .stepChargeUseTitle {
      font-size: 14px;
      color: $mainText8Color;
      height: 45px;
      line-height: 45px;
    }
    .chargeUserItem {
      display: flex;
      align-items: center;
      padding-left: 30px;
    }
    .chargeUserNick {
      font-size: 14px;
      color: $mainText8Color;
      height: 40px;
      line-height: 40px;
    }
    .icon {
      font-size: 13px;
      color: $warning;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border: 1px solid $warning;
      border-radius: 50%;
      margin-left: 15px;
      cursor: pointer;
    }
  }
  .promptShow {
    margin-bottom: 30px;
  }
  .selectBtn {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .btn {
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .stepChargeUserName {
    display: flex;
    align-items: center;
    .name {
      width: 80px;
      font-size: 14px;
      color: $mainText8Color;
    }
  }
}

:local .hidden {
  display: none;
}

:local .modal {
  position: relative;
  text-align: center;
  .adminList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .adminItem {
      height: 45px;
      line-height: 45px;
      cursor: pointer;
      margin: 0 15px;
      .adminTitle {
        font-size: 14px;
        color: $mainTextColor;
        &:focus {
          border: 1px solid $mainBorderColor;
        }
      }
      .adminIsChoose {
        border-radius: 5px;
        border: 1px solid $mainSelectBgColor;
        color: $mainSelectBgColor;
        font-size: 14px;
        &:focus {
          border: 1px solid $mainSelectBgColor;
        }
      }
    }
  }
  .saveBtn {
    cursor: pointer;
    margin-top: 15px;
  }
}
