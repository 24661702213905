@import "../../../global.scss";
:local body {
  background: $mainWhiteColor !important;
}

:local .mealCard {
  position: relative;
  overflow-y: auto;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: $maskLayer;
  }
  .hidden {
    display: none;
  }
}
:global {
  .ant-upload {
    margin-left: 15px;
    span {
      height: 0em;
    }
  }
  .am-button {
    background-color: #108ee9 !important;
    color: #fff !important;
    height: 40px !important;
    font-size: 14px !important;
    line-height: 40px !important;
    padding: 0 20px !important;
  }
}
:local .form {
  font-size: 14px;
  color: $mainText8Color;
  height: 90vh;
  input {
    text-align: right;
    font-size: 16px !important;
    color: $mainText8Color !important;
  }
  textarea {
    font-size: 16px;
    color: $mainText8Color;
  }
  .isGraduate {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }
  .tips {
    margin: 10px;
    padding: 10px 18px;
    font-size: 14px;
    color: $mainTextColor;
    border: 2px dotted $mainTextColor;
    // margin: 0 12px;
    border-radius: 5px;
    margin-top: 10px;
    white-space: normal;
  }
  :global .ant-upload {
    margin-left: 10px !important;
    margin-bottom: 10px !important;
    line-height: 0.5;
  }
  :global .ant-upload-list {
    padding: 0 10px;
    padding-bottom: 20px !important;
  }
  :global .am-list-item .am-input-label {
    font-size: 15px;
    color: $mainTextColor;
    min-width: 95px;
  }
  :global .am-list-item .am-list-line .am-list-content {
    font-size: 15px;
    color: $mainTextColor;
  }
  .theme {
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    color: $mainTextBlackColor;
  }
  .item {
    padding-left: 15px;
    .title {
      height: 45px;
      line-height: 45px;
      font-size: 15px;
    }
    .detail {
      padding-right: 15px;
      flex: 1;
      justify-content: flex-end;
      display: flex;
    }
  }
  .addPatent {
    text-align: center;
    height: 45px;
    line-height: 45px;
    .icon {
      font-size: 20px;
      color: $mainText8Color;
    }
  }
  .deleteIcon {
    color: $warning;
    font-size: 18px;
    margin-left: 15px;
  }
  .address {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .hasStudyAbroad {
    flex: 1 1;
    justify-content: flex-end;
    display: flex;
    padding-right: 23px;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $mainWhiteColor inset !important;
    -webkit-text-fill-color: $mainTextColor !important;
  }
  :global {
    .am-list-item .am-input-label {
      font-size: 15px;
      color: $mainTextColor;
    }
    .am-list
      .am-list-item.am-radio-item
      .am-list-line
      .am-list-extra
      .am-radio-inner {
      right: 0px;
      top: 12px;
      padding: 10px;
      border: 1px solid $mainBorderColor;
      border-radius: 5px;
    }
    .am-radio-inner:after {
      top: 0.5px;
      right: 6px;
    }
    .am-textarea-label {
      font-size: 15px;
      color: $mainText6Color;
    }
    .am-textarea-control textarea {
      font-size: 15px;
      color: $mainText6Color;
    }
  }
  .gender {
    .title {
      font-size: 15px;
      color: $mainText6Color;
    }
    :global {
      .am-list-item .am-list-line {
        padding-right: 40px !important;
      }
      .am-list-content {
        font-size: 15px;
        color: $mainText6Color;
      }
    }
    .genderLine {
      :global {
        .am-list-line:after {
          height: 0 !important;
        }
      }
    }
  }
}

:local .operation {
  margin: 30px 0 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  .btn {
    margin: 0 10px;
    width: 140px;
  }
}

:local .primary {
  margin: 30px 0 0;
  text-align: center;
  position: unset;
  width: 100%;
  bottom: 30px;
  .btn {
    margin: 0 10px;
    width: 140px;
  }
}