@import "../../../global.scss";
:local body {
  background: $mainWhiteColor !important;
}

:local .idCardRealName {
  position: relative;
  overflow-y: auto;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: $maskLayer;
  }
  .hidden {
    display: none;
  }
}

:local .form {
  font-size: 14px;
  color: $mainText8Color;
  .input {
    input {
      text-align: right;
      font-size: 16px;
      color: $mainText8Color;
    }
  }
  .isHouseholdRegister {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }
  :global {
    .am-list-item .am-input-label {
      font-size: 15px;
      color: $mainTextColor;
    }
    .am-list
      .am-list-item.am-radio-item
      .am-list-line
      .am-list-extra
      .am-radio-inner {
      right: 0px;
      top: 12px;
      padding: 10px;
      border: 1px solid $mainBorderColor;
      border-radius: 5px;
    }
    .am-radio-inner:after {
      top: 0.5px;
      right: 6px;
    }
    .am-textarea-label {
      font-size: 15px;
      color: $mainText6Color;
    }
    .am-textarea-control textarea {
      font-size: 15px;
      color: $mainText6Color;
    }
  }
  .gender {
    .title {
      font-size: 15px;
      color: $mainText6Color;
    }
    :global {
      .am-list-item .am-list-line {
        padding-right: 40px !important;
      }
      .am-list-content {
        font-size: 15px;
        color: $mainText6Color;
      }
    }
    .genderLine {
      :global {
        .am-list-line:after {
          height: 0 !important;
        }
      }
    }
  }
  .tips {
    padding: 10px 18px;
    font-size: 14px;
    color: $mainTextColor;
    border: 2px dotted $mainTextColor;
    margin: 0 12px;
    border-radius: 5px;
    margin-top: 10px;
  }
  .toolps {
    padding: 10px 18px;
    font-size: 14px;
    color: $warning;
    border: 2px dotted $maskLayer;
    margin: 0 22px;
    border-radius: 5px;
    margin-top: 18px;
  }
  :global .am-list-item .am-input-label {
    font-size: 15px;
    color: $mainTextColor !important;
  }
  .title {
    font-size: 15px;
    color: $mainTextColor;
  }
  .step {
    background: $mainWhiteColor;
    .title {
      font-size: 14px;
      color: $mainTextColor;
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid $mainHoverBgColor;
      padding-left: 15px;
    }
    .alert {
      margin: 10px 15px;
    }
  }
}

:local .content {
  padding: 15px;
  .meg {
    .author {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        font-size: 14px;
        color: $mainText6Color;
        height: 40px;
        line-height: 40px;
      }
      .time {
        font-size: 12px;
        color: $mainText8Color;
      }
    }
    .dialogLeft {
      text-align: left;
      padding: 5px 0;
    }
    .dialogRight {
      text-align: right;
      padding: 5px 0;
    }
    .left {
      padding: 2px 4px;
      border: 1px solid $mainBorderColor;
      border-radius: 10px;
      font-size: 14px;
      color: $mainText8Color;
      display: inline-block;
    }
    .right {
      padding: 2px 4px;
      border: 1px solid $mainSelectBgColor;
      border-radius: 10px;
      font-size: 14px;
      color: $mainSelectBgColor;
      display: inline-block;
    }
  }
}

:local .operation {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
  .btn {
    width: 30%;
  }
}
