@import "../../../global.scss";
:local body {
  background: $mainWhiteColor !important;
}

:local .meettingRoom {
  position: relative;
  overflow-y: auto;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: $maskLayer;
  }
  .hidden {
    display: none;
  }
}

:local .form {
  font-size: 14px;
  color: $mainText8Color;
  min-height: 90vh;
  padding-bottom: 80px;
  input {
    text-align: right;
    font-size: 16px !important;
    color: $mainText8Color !important;
  }
  textarea {
    font-size: 16px;
    color: $mainText8Color;
  }
  .isGraduate {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }
  :global .am-list-item .am-input-label {
    font-size: 15px;
    color: $mainTextColor;
  }
  :global .am-list-item .am-list-line .am-list-content {
    font-size: 15px;
    color: $mainTextColor;
  }
  .theme {
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    color: $mainTextBlackColor;
  }
  .item {
    padding-left: 15px;
    .title {
      height: 45px;
      line-height: 45px;
      font-size: 15px;
    }
    .detail {
      padding-right: 15px;
      flex: 1;
      justify-content: flex-end;
      display: flex;
    }
  }
  .addPatent {
    text-align: center;
    height: 45px;
    line-height: 45px;
    .icon {
      font-size: 20px;
      color: $mainText8Color;
    }
  }
  .deleteIcon {
    color: $warning;
    font-size: 18px;
    margin-left: 15px;
  }
  .address {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .hasStudyAbroad {
    flex: 1 1;
    justify-content: flex-end;
    display: flex;
    padding-right: 23px;
  }
  :global .am-list-item .am-list-line .am-list-extra {
    flex: 1;
  }
  .prompt {
    padding-top: 15px;
    .tips {
      border: 2px dotted $mainBottomColor;
      padding: 15px;
      margin: 0 15px;
      border-radius: 5px;
      font-size: 14px;
      color: $carouselPointColor;
    }
  }
}

:local .checkedItem {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid rgb(231, 231, 231);
  .title {
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    color: #666;
  }
}
:local .operation {
  margin: 30px 0 0;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 30px;
  .btn {
    margin: 0 10px;
    width: 140px;
  }
}

:local .primary {
  margin: 30px 0 0;
  text-align: center;
  position: unset;
  width: 100%;
  bottom: 30px;
  .btn {
    margin: 0 10px;
    width: 140px;
  }
}