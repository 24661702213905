@import '../global.scss';
:local .basicForm {
    .header {
        background: $mainWhiteColor;
        padding: 15px;
        .description {
            line-height: 2.5;
            margin-top: 10px;
            .title {
                font-size: 18px;
                color: $mainTextBlackColor;
            }
            .detail {
                font-size: 14px;
                color: $mainTextColor;
            }
        }
    }
    .content {
        margin: 15px;
        padding: 15px;
        width:100%;
        background: $mainWhiteColor;
        // @media (min-width: 576px) {
        //     :global .ant-col-sm-16 {
        //         width: 30.666667%;
        //     }
        // }
        .secondaryColor {
            color: $mainBorderColor;
            font-size: 14px;
        }
        .hidden {
            display: none;
        }
        .noneMarginBottom {
            margin-bottom: 0;
        }
        .btn {
            margin-right: 15px;
        }
        .datePicker {
            width: 100% !important;
        }
        .addressDescription {
            margin-bottom: 0;
            position: absolute;
        }
    }
}
