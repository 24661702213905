@import '../../../sass/global.scss';
:local .base {
    .baseBox {
        display: flex;
        justify-content: space-between;
        background: $mainWhiteColor;
        margin: 15px;
        padding: 15px 0;
        .left {
            width: 225px;
        }
        .right {
            border-left: 1px solid $mainBorderColor;
            flex: 1;
            padding-left: 40px;
            .title {
                font-size: 20px;
                color: $mainTextBlackColor;
                margin-bottom: 12px;
            }
        }
    }
    .hidden {
        display: none;
    }
    .form {
        width: 100%;
        :global .ant-form-item-label {
            // text-align: left;
            :global .ant-form-item-required:before {
                content: "";
            }
        }
        .tooltip {
            color: $mainBorderColor;
            margin-left: 5px;
        }
        .autoLogin {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .autoLoginTitle {
                flex: 1;
                text-align: left;
            }
            .forgetPassword {
                width: 150px;
                text-align: right;
            }
        }
        .verificationCode {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .verificationCodeInput {
                flex: 1,
            }
            .verificationCodeBtn {
                width: 150px;
                text-align: right;
            }
        }
        .verificationCodePic {
            display: flex;
            align-items: center;
            .input {
                flex: 1;
            }
            .img {
                margin-left: 10px;
                width: 120px;
                height: 40px;
                cursor: pointer;
            }
        }
        .autoLogin {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .checkBox {
                flex: 1;
                text-align: left;
            }
            .checkBoxForget {
                width: 120px;
                text-align: right;
            }
        }
        .control {
            display: flex;
            align-items: center;
            .btn {
                margin-right: 15px;
                margin-top: 45px;
            }
        }
        .controlLegalPerson {
            display: flex;
            align-items: center;
            .btn {
                margin-right: 15px;
                margin-top: 150px;
            }
        }
        .realNameTitle {
            font-size: 14px;
            color: $mainTextColor;
            height: 40px;
            line-height: 40px;
        }
        .input {
            width: 100%;
        }
        .panel {
            margin-bottom: 25px;
            .shareholderList {
                .shareholderItem {
                    .shareholderTitle {
                        display: flex;
                        align-items: center;
                        .theme {
                            font-size: 14px;
                            color: $mainTextColor;
                        }
                        .icon {
                            font-size: 16px;
                            color: $warning;
                            margin-left: 15px;
                            cursor: pointer;
                        }
                    }
                    .item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        padding-left: 10px;
                        .label {
                            font-size: 14px;
                            color: $mainText8Color;
                            width: 100px;
                            text-align: right;
                            padding-right: 10px;
                        }
                        .input {
                            flex: 1;
                        }
                    }
                }
            }
            .shareholderAdd {
                text-align: center;
                font-size: 18px;
                color: $mainTextColor;
            }
        }
    }
}

:local .hidden {
    display: none;
}
