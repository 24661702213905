@import "../../../sass/global.scss";
:local .companyList {
  .title {
    font-size: 14px;
    height: 55px;
    text-align: left;
    line-height: 55px;
    border-bottom: 1px solid $mainBoxShadowColor;
    margin-bottom: 15px;
    background: $mainWhiteColor;
    padding: 0 30px;
  }
  .filters {
    padding: 15px;
    margin: 15px 15px 0;
    background: $mainWhiteColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
      width: 450px;
      font-size: 14px;
      color: $mainWhiteColor;
    }
    .selectDate {
      width: 450px;
    }
    .item {
      .drown {
        margin-left: 15px;
      }
      .filterBtn {
        margin-left: 15px;
      }
    }
  }
  .table {
    background: $mainWhiteColor;
    padding: 15px;
    margin: 0 15px 15px;
    .avatarBox {
      width: 60px;
      cursor: pointer;
      .avatar {
        width: 100%;
      }
    }
    .btn {
      cursor: pointer;
      color: $mainSelectBgColor;
    }
  }
}

:local .formList {
  position: relative;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .title {
      font-size: 14px;
      color: $mainTextColor;
      padding-right: 10px;
      width: 80px;
      text-align: right;
    }
    .condition {
      width: 120px;
      margin-right: 10px;
      word-wrap: wh;
    }
    .age {
      display: flex;
      flex: 1;
      .numberInput {
        flex: 1;
      }
    }
    .input {
      flex: 1;
    }
  }
  .hidden {
    display: none;
  }
}
