@import '../../global.scss';
:local .index {
    position: relative;
    overflow-y: auto;
    .loading {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background: $maskLayer;
    }
    .list {
        background: $mainHoverBgColor;
        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 0 5px 15px;
        }
        .item {
            background: $mainWhiteColor;
            padding: 15px;
            margin-bottom: 15px;
            border-top: 1px solid $mainHoverBgColor;
            .num {
                font-size: 15px;
                color: $mainTextBlackColor;
                height: 35px;
                line-height: 35px;
            }
            .title {
                font-size: 15px;
                color: $mainText8Color;
                margin-right: 10px;
            }
            .detail {
                font-size: 15px;
                color: $mainTextColor
            }
            .red {
                color: $warning;
            }
            .green {
                color: $mainGreen;
            }
        }
    }
}
