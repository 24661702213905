@import '../../../global.scss';
:local .login {
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: $mainWhiteColor;
    height: 100vh;
    .loginBox {
        height: 100vh;
    }
    .headerBox {
        background: $activeCarouselPointColor;
        .header {
            height: 60px;
            width: 1200px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .logo {
                color: $mainWhiteColor;
                .logoImg {
                    width: 120px;
                    overflow: hidden;
                }
                .title {
                    color: $mainWhiteColor;
                }
            }
            a {
                color: $mainWhiteColor;
                font-size: 14px;
                text-decoration: none;
                &:hover {
                    color: $mainBgColor;
                }
            }
            .line {
                color: $mainBoxBgColor;
                margin: 0 5px;
                font-size: 16px;
            }
        }
    }
    .verificationCode {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .verificationCodeTitle {
            flex: 1;
        }
        .verificationCodeBtn {
            width: 110px;
            margin-left: 10px;
            text-align: center;
        }
    }
    .stepBox {
        margin-bottom: 30px;
        background: $mainBgColor;
        padding: 10px 0;
        position: relative;
        .steps {
            width: 1200px;
            margin: 0 auto;
        }
        :global .ant-steps-item {
            text-align: left;
        }
    }
    .result {
        width: 1200px;
        margin: 60px auto;
        padding: 60px 30px;
        border: 1px dashed $mainBorderColor;
        .icon {
            width: 72px;
            height: 72px;
            background: $mainGreen;
            border-radius: 50%;
            overflow: hidden;
            line-height: 72px;
            text-align: center;
            font-size: 25px;
            color: $mainWhiteColor;
        }
        .prompt {
            font-size: 24px;
            margin-top: 30px;
        }
        .btn {
            margin-top: 10px;
        }
    }
    .title {
        font-size: 14px;
        color: $mainTextColor;
        margin: 15px 0 30px;
    }
    .hidden {
        display: none;
    }
    .submit {
        position: relative;
        .btn {
            margin: 0 10px;
        }
        .otherLoginStyle {
            height: 45px;
            line-height: 45px;
            text-align: left;
            display: flex;
            align-items: center;
            margin-top: 15px;
            justify-content: space-between;
            .iconBox {
                display: flex;
                flex: 1;
            }
            .icon {
                color: $mainWhiteColor;
                background: $mainBoxBgColor;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                display: block;
                line-height: 29px;
                text-align: center;
                font-size: 17px;
                margin-left: 10px;
                -webkit-transition: all .2s linear;
                transition: all .2s linear;
                &:hover {
                    color: $mainSelectBgColor;
                    background: $mainWhiteColor;
                }
            }
        }
    }
    .footer {
        margin-bottom: 30px;
        font-size: 14px;
        color: $mainTextBlackColor;
    }
    @media (min-width: 576px) {
        :global .ant-col-sm-offset-10 {
            margin-left: 39.666667%;
        }
        :global .ant-col-offset-10 {
            margin-left: 39.666667%;
        }
        :global .ant-form-item-with-help {
            margin-bottom: 25px;
        }
    }
}

//手机
@media (max-width: 576px) {
    :local .login {
        text-align: center;
        display: flex;
        flex-direction: column;
        overflow: auto;
        background: $mainWhiteColor;
        height: 100vh;
        .loginBox {
            height: 100vh;
        }
        .form {
            padding: 0 10px;
        }
        .headerBox {
            background: $activeCarouselPointColor;
            .header {
                height: 60px;
                width: 100%;
                padding: 0 10px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .logo {
                    color: $mainWhiteColor;
                    .logoImg {
                        width: 100px;
                        max-height: 36px;
                        overflow: hidden;
                    }
                    .title {
                        color: $mainWhiteColor;
                    }
                }
                a {
                    color: $mainWhiteColor;
                    font-size: 14px;
                    text-decoration: none;
                    &:hover {
                        color: $mainBgColor;
                    }
                }
                .line {
                    color: $mainBoxBgColor;
                    margin: 0 5px;
                    font-size: 16px;
                }
            }
        }
        .stepBox {
            width: 100%;
            overflow: hidden;
            margin-bottom: 30px;
            background: $mainBgColor;
            padding: 10px;
            position: relative;
            .steps {
                width: 1200px;
                margin: 0 auto;
            }
            :global .ant-steps-item {
                text-align: left;
            }
        }
        .result {
            width: 90%;
            padding: 30px 0;
            margin: 0 5%;
            border: 1px dashed $mainBorderColor;
            .icon {
                width: 32px;
                height: 32px;
                background: $mainGreen;
                border-radius: 50%;
                overflow: hidden;
                line-height: 32px;
                text-align: center;
                font-size: 20px;
                color: $mainWhiteColor;
            }
            .prompt {
                font-size: 18px;
                margin-top: 15px;
            }
            .btn {
                margin-top: 10px;
            }
        }
        .title {
            font-size: 14px;
            color: $mainTextColor;
            margin: 15px 0 30px;
        }
        .hidden {
            display: none;
        }
        .submit {
            position: relative;
            .btn {
                margin: 0 10px;
            }
            .otherLoginStyle {
                height: 45px;
                line-height: 45px;
                text-align: left;
                display: flex;
                align-items: center;
                margin-top: 15px;
                justify-content: space-between;
                .iconBox {
                    display: flex;
                    flex: 1;
                }
                .icon {
                    color: $mainWhiteColor;
                    background: $mainBoxBgColor;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    display: block;
                    line-height: 29px;
                    text-align: center;
                    font-size: 17px;
                    margin-left: 10px;
                    -webkit-transition: all .2s linear;
                    transition: all .2s linear;
                    &:hover {
                        color: $mainSelectBgColor;
                        background: $mainWhiteColor;
                    }
                }
            }
        }
        .footer {
            margin-bottom: 30px;
            font-size: 14px;
            color: $mainTextBlackColor;
        }
        @media (min-width: 576px) {
            :global .ant-col-sm-offset-10 {
                margin-left: 39.666667%;
            }
            :global .ant-col-offset-10 {
                margin-left: 39.666667%;
            }
            :global .ant-form-item-with-help {
                margin-bottom: 25px;
            }
        }
    }
}
