@import '../../../global.scss';
:local .modifyPassword {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background: $mainHoverBgColor1;
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    .navbar {
        height: 45px;
        line-height: 45px;
        margin-bottom: 40px;
        border-bottom: 1px solid $mainHoverBgColor;
    }
    .logo {
        width: 200px;
        max-height: 80px;
        margin: 15px auto 0;
        overflow: hidden;
        .logoImg {
            width: 100%;
        }
    }
    .title {
        font-size: 14px;
        color: $mainTextColor;
        margin: 15px 0 30px;
    }
    .control {
        .btn {
            width: 100%;
            position: relative;
        }
    }
    :global .ant-form-item-with-help {
        margin-bottom: 25px;
    }
    @media (max-width:576px) {
        :global {
            .ant-col-xs-20 {
                width: 83.33333333%;
            }
        }
    }
    @media (min-width:577px) {
        :global {
            .ant-col-sm-offset-10 {
                margin-left: 39.666667%;
            }
        }
    }
}
