@import '../../../global.scss';
:local .register {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background: $mainHoverBgColor;
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    padding-top: 50px;
    .loginBox {
        height: 1600px;
    }
    .logo {
        width: 200px;
        max-height: 80px;
        margin: 15px auto 0;
        overflow: hidden;
        .logoImg {
            width: 100%;
        }
    }
    .title {
        font-size: 16px;
        color: $mainTextBlackColor;
        margin: 25px 0;
    }
    .verificationCode {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .verificationCodeTitle {
            flex: 1;
        }
        .verificationCodeBtn {
            width: 110px;
            margin-left: 10px;
            text-align: center;
        }
    }
    .tabs {
        :global .ant-tabs-bar {
            border-bottom: none;
        }
        .form {
            .tooltip {
                color: $mainBorderColor;
                margin-left: 5px;
            }
            .btnBox {
                display: flex;
                justify-content: space-between;
                .btn {
                    width: 150px;
                }
            }
        }
    }
    .footer {
        margin-bottom: 30px;
        font-size: 14px;
        color: $mainTextBlackColor;
    }
    @media (max-width: 576px) {
        :global .ant-form-item-with-help {
            margin-bottom: 25px;
        }
        :global .ant-col-xs-20 {
            width: 83.33333333%;
        }
    }
    @media (min-width: 576px) {
        :global .ant-col-sm-offset-10 {
            margin-left: 39.666667%;
        }
        :global .ant-col-offset-10 {
            margin-left: 39.666667%;
        }
        :global .ant-form-item-with-help {
            margin-bottom: 25px;
        }
    }
}
