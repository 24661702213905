@import '../../global.scss';
:local body {
    background: $mainWhiteColor !important;
}

:local .info {
    position: relative;
    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        background: $maskLayer;
    }
    .hidden {
        display: none;
    }
    .form {
        font-size: 14px;
        color: $mainText8Color;
        height: 90vh;
        .input {
            input {
                text-align: right;
                font-size: 16px;
                color: $mainText8Color;
            }
        }
        .toolps {
            padding: 10px 18px;
            font-size: 14px;
            color: $warning;
            border: 2px dotted $maskLayer;
            margin: 0 22px;
            border-radius: 5px;
            margin-top: 18px;
        }
        :global {
            .am-list-item .am-input-label {
                font-size: 15px;
                color: $mainTextColor;
            }
            .am-list .am-list-item.am-radio-item .am-list-line .am-list-extra .am-radio-inner {
                right: 0px;
                top: 12px;
                padding: 10px;
                border: 1px solid $mainBorderColor;
                border-radius: 5px;
            }
            .am-radio-inner:after {
                top: 0.5px;
                right: 6px;
            }
            .am-textarea-label {
                font-size: 15px;
                color: $mainText6Color;
            }
            .am-textarea-control textarea {
                font-size: 15px;
                color: $mainText6Color;
            }
        }
        .gender {
            .title {
                font-size: 15px;
                color: $mainText6Color;
            }
            :global {
                .am-list-item .am-list-line {
                    padding-right: 30px !important;
                }
                .am-list-content {
                    font-size: 15px;
                    color: $mainText6Color;
                }
            }
            .genderLine {
                :global {
                    .am-list-line:after {
                        height: 0 !important;
                    }
                }
            }
        }
        .title {
            font-size: 15px;
            color: $mainTextColor;
        }
        .avatarFather {
            height: 100px;
        }
        .avatar {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 250px;
        }
        .control {
            text-align: center;
            margin-top: 30px;
            .btn {
                margin: 0 10px;
                width: 140px;
            }
        }
    }
}
