@import '../../../global.scss';
:local body {
    background: $mainWhiteColor !important;
}

:local .parkingInfo {
    position: relative;
    overflow-y: auto;
    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        background: $maskLayer;
    }
    .hidden {
        display: none;
    }
}

:local .form {
    font-size: 14px;
    color: $mainText8Color;
    .input {
        input {
            text-align: right;
            font-size: 16px;
            color: $mainText8Color !important;
        }
    }
    .box {
        text-align: center;
        margin: 30px 0 15px;
        .btn {
            margin: 0 10px;
            width: 140px;
        }
    }
    .promptPadding {
        padding: 15px 0;
        border-bottom: 1px solid $mainHoverBgColor;
    }
    .prompt {
        padding: 10px 18px;
        color: $mainBorderColor;
        border: 2px dotted $maskLayer;
        margin: 0 22px;
        border-radius: 5px;
    }
    :global .am-list-item .am-input-label {
        font-size: 15px;
        color: $mainTextColor;
    }
    :global .am-list-item .am-list-line .am-list-content {
        font-size: 15px;
        color: $mainTextColor;
    }
    .title {
        font-size: 15px;
        color: $mainTextColor;
    }
}

:local .operation {
    display: flex;
    justify-content: space-evenly;
    margin: 30px 0;
    .btn {
        width: 30%;
    }
}
