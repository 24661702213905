@import "../../../global.scss";
:local body {
  background: $mainWhiteColor !important;
}

:local .schoolInfo {
  position: relative;
  overflow-y: auto;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: $maskLayer;
  }
  .hidden {
    display: none;
  }
}

:local .form {
  font-size: 14px;
  color: $mainText8Color;
  .input {
    input {
      text-align: right;
      font-size: 16px;
      color: $mainText8Color;
    }
  }
  .addPatent {
    margin-left: 15px;
    height: 45px;
    line-height: 45px;
    .icon {
      font-size: 20px;
      color: $mainText8Color;
    }
  }
  .isGraduate,
  .isOverseas {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }
  .toolps {
    padding: 10px 18px;
    font-size: 14px;
    color: $textGrayColor;
    border: 2px dotted $maskLayer;
    margin: 0 22px;
    border-radius: 5px;
    margin-top: 18px;
  }
  .box {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .btn {
      margin: 0 10px;
      width: 140px;
    }
  }

  :global .am-list-item .am-input-label {
    font-size: 15px;
    color: $mainTextColor;
  }
  :global .am-list-item .am-list-line .am-list-content {
    font-size: 15px;
    color: $mainTextColor;
  }
}

:local .operation {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
  .btn {
    width: 30%;
  }
}
:local .diploma {
  margin-left: 15px;
  color: #888;
  font-size: 15px;
  margin-top: 15px;
}
:local .diplomaPic {
  margin-left: 6px;
  padding-left: 0;
}
