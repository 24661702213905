:local .rentList {
  display: flex;
  margin: 30px auto;
  min-width: 600px;
  max-width: 1300px;
  border: 1px solid #333;
  overflow-x: auto;
  padding: 5px 20px;
  .timeSectionList {
    margin: 35px 20px 0 5px;
    .item {
      height: 32px;
      line-height: 32px;
      margin-bottom: 5px;
    }
  }
  .dateRentItem {
    margin-right: 10px;
    .date {
      line-height: 30px;
      height: 30px;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }
}

:local .halfHourRentInfo {
  width: 60px;
  height: 16px;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  &.upperPart {
    margin-top: 5px;
    border-top: 1px solid #333;
  }
  &.lowerPart {
    border-bottom: 1px solid #333;
  }
  .tooltip {
    width: 60px;
    height: 16px;
  }
}
