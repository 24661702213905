@import '../../global.scss';
:local .index {
    .loading {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background: $maskLayer;
    }
    .list {
        background: $mainWhiteColor;
        .item {
            border-top: 1px solid $mainHoverBgColor;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            .icon {
                font-size: 20px;
                color: $mainText8Color;
            }
            .leftIcon {
                font-size: 20px;
                color: $mainText8Color;
            }
            .title {
                font-size: 15px;
                color: $mainText6Color;
                margin-left: 10px;
            }
        }
        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}
