@import '../../../sass/global.scss';
:local .information {
    margin: 25px;
    .base {
        background: $mainWhiteColor;
        margin-right: 25px;
        padding: 25px;
        .header {
            text-align: center;
            padding-bottom: 25px;
            border-bottom: 1px dashed $mainBottomColor;
            .avatar {
                width: 104px;
                height: 104px;
            }
            .title {
                font-size: 20px;
                color: $mainTextBlackColor;
                font-weight: 600;
                margin: 10px 0;
            }
            .introduct {
                font-size: 14px;
                color: $mainTextColor;
            }
            .other {
                margin-top: 30px;
                .box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    .detail {
                        font-size: 14px;
                        color: $mainTextColor;
                        margin-left: 10px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .aliIcon {
                        font-size: 16px;
                        width: 20px;
                    }
                }
            }
        }
        .tags {
            margin: 25px 0;
            padding-bottom: 25px;
            border-bottom: 1px dashed $mainBottomColor;
            .tag {
                margin-bottom: 10px;
            }
        }
        .team {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .box {
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 24px;
                padding-right: 24px;
                .img {
                    width: 25px;
                    height: 25px;
                    overflow: hidden;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .title {
                    flex: 1;
                    font-size: 14px;
                    color: $mainTextColor;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .about {
        background: $mainWhiteColor;
        padding: 25px;
        .article {
            :global .ant-comment {
                border-top: 1px solid $mainHoverBgColor;
                &:first-child {
                    border-top: none;
                }
            }
            .name {
                font-size: 17px;
                color: $mainTextBlackColor;
                font-weight: 600;
            }
        }
        .application {
            :global .ant-card-bordered {
                transition: all .2s linear;
                -webkit-transition: all .2s linear;
                box-shadow: 0 0 10px -1px transparent;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 2px 8px $mainHoverBgColor;
                    border-color: $mainHoverBgColor;
                }
            }
            .box {
                margin-bottom: 16px;
                padding: 0 12px;
                .img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
                .content {
                    display: flex;
                    align-items: center;
                    .left {
                        width: 50%;
                        .title {
                            font-size: 14px;
                            color: $mainTextColor;
                            margin-bottom: 10px;
                        }
                        .detail {
                            font-size: 18px;
                            color: $mainTextBlackColor;
                        }
                        .unit {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .projects {
            :global .ant-card-bordered {
                transition: all .2s linear;
                -webkit-transition: all .2s linear;
                box-shadow: 0 0 10px -1px transparent;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 2px 8px $mainHoverBgColor;
                    border-color: $mainHoverBgColor;
                }
            }
            .box {
                padding: 0 12px;
                margin-bottom: 16px;
                .content {
                    .name {
                        font-size: 16px;
                        color: $mainTextBlackColor;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .description {
                        font-size: 14px;
                        color: $mainTextColor;
                        margin: 5px 0;
                        height: 45px;
                        word-break: break-all;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: block-axis;
                    }
                    .footer {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 15px;
                        .avatars {
                            display: flex;
                            position: relative;
                            left: 10px;
                            .avatarBox {
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                overflow: hidden;
                                .avatar {
                                    width: 100%;
                                }
                                &:nth-child(2) {
                                    position: relative;
                                    left: -10px;
                                }
                                &:nth-child(3) {
                                    position: relative;
                                    left: -20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
