@import '../../../global.scss';
html {
    touch-action: none;
}

:local .index {
    position: relative;
    .banner {
        height: 200px;
        overflow: hidden;
        img {
            width: 100%;
            vertical-align: top;
        }
    }
    .btns {
        margin: 35px 20px 25px;
        text-align: center;
        .btn {
            margin: 0 35px;
        }
    }
    .prompt {
        margin: 0 20px;
        padding: 15px;
        border: 2px dotted $mainBorderColor;
        border-radius: 5px;
        font-size: 14px;
        color: $mainText8Color;
    }
}
